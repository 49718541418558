<template>
    <div>
        <calendar-month />
    </div>
</template>
<script>
    import calendarMonth from '@/components/calendar/calendarMonth.vue'

    export default{
        name: 'Reservation',
        components: {
            calendarMonth
        }
    }
</script>
<style>
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.calendar-month-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
